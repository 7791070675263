<template>
  <div class="flex flex-col gap-4 py-4 w-full h-full px-3 overflow-x-hidden relative">
    <h1 class="text-xl border-b text-left font-bold flex text-darkPurple">Time Off</h1>
    <ErrorPage v-if="plan === 'basic'" />
    <div v-else>
      <c-card class="">
        <Tabs
          class="mt-2 mx-2"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
      </c-card>
      <div class="h-full">
        <leave-types v-if="activeTab === 'Leave Types'" />
        <leave-balance v-if="activeTab === 'Leave Balances'" />
      </div>
    </div>
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error404";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab"
import CCard from "@scelloo/cloudenly-ui/src/components/card"

export default {

  components: {
    Tabs,
    CCard,
    ErrorPage,
    LeaveTypes: () => import('./TimeOff'),
    LeaveBalance: () => import('./LeaveBalance'),
  },
  computed: {
    plan() {
      if (this.$store.state.subscription) {
        return this.$store.state.subscription.plan
      } return null
    },
  },

  data() {
    return {
      activeTab: "Leave Types",
      tabsItems: ["Leave Types", "Leave Balances"]
    }
  }
};
</script>
